import React from "react";
import { socialData } from "../data";

const Socials = () => {
  return (
    <div className="flex gap-x-[10px]">
      {socialData.map((item, index) => {
        return (
          <div
            key={index}
            className="border border-white/20 rounded-full w-[35px] h-[35px]
          flex items-center justify-center text-sm hover:text-accent
          hover:border-accent transition-all"
          >
            {item.element}
          </div>
        );
      })}
    </div>
  );
};

export default Socials;
