// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaDiscord,
} from "react-icons/fa";
// import images
import AboutImg from "../src/assets/img/about/about-pizza.png";
import ModelWhiteImg from "../src/assets/img/model-white.png";
import ModelBlackImg from "../src/assets/img/model-black.png";
import Margherita from "../src/assets/img/menu/margherita.jpeg";
import Mix from "../src/assets/img/menu/mix.jpeg";
import Kofteli from "../src/assets/img/menu/kofteli.jpeg";
import Vejeteryan from "../src/assets/img/menu/vejeteryan.jpeg";
import Asado from "../src/assets/img/menu/asado.jpeg";
import Bambino from "../src/assets/img/menu/bambino.jpeg";
import Formaggi from "../src/assets/img/menu/formaggi.jpeg";
import Funghi from "../src/assets/img/menu/funghi.jpeg";
import AllTonto from "../src/assets/img/menu/all-tonto.jpeg";
import BBQ from "../src/assets/img/menu/bbq.jpeg";
import Fume from "../src/assets/img/menu/fume-et.jpeg";
import SignatureImg from "../src/assets/img/team/signature.png";
import ChefImg from "../src/assets/img/team/chef.png";
import Avatar1 from "../src/assets/img/testimonial/avatar1.png";
import Avatar2 from "../src/assets/img/testimonial/avatar2.png";
import Avatar3 from "../src/assets/img/testimonial/avatar3.png";
import YemekSepeti from "../src/assets/img/socialicons/yemek-sepeti.png";

export const navData = [
  { href: "#hero", name: "ana sayfa" },
  { href: "#about", name: "hakkımızda" },
  { href: "#menu", name: "menü" },
  // { href: "#team", name: "ekibimiz" },
  { href: "#testimonial", name: "yorumlar" },
  // { href: "#reservation", name: "rezervasyon" },
  { href: "#footer", name: "iletişim" },
];

export const heroData = {
  pretitle: "Eşsiz babaanne tarifi ile",
  title: "Grazie Pizza",
  subtitle: "Lezzetin İtalyan Kalbinde Buluştuğu Yer",
  btnText: "Daha Fazlasını Keşfedin",
};

export const socialData = [
  {
    href: "https://www.instagram.com/graziepizzaormankoy/",
    icon: <FaInstagram />,
    element: (
      <a
        href="https://www.instagram.com/graziepizzaormankoy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
    ),
  },
  {
    href: "https://www.yemeksepeti.com/restaurant/dzo2/grazie-pizza",
    icon: (
      <img
        src={YemekSepeti}
        alt="YemekSepeti"
        style={{ borderRadius: "100%" }}
      />
    ),
    element: (
      <a
        href="https://www.yemeksepeti.com/restaurant/dzo2/grazie-pizza"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={YemekSepeti}
          alt="YemekSepeti"
          style={{
            borderRadius: "100%",
            objectFit: "contain",
          }}
        />
      </a>
    ),
  },
];

export const aboutData = {
  pretitle: "hikayemiz",
  title: "biz kimiz",
  subtitle:
    "Grazie Pizza olarak, her dilde mutluluk anlamına gelen Grazie'yi işimize yansıtıyoruz. Misafirlerimize sadece lezzetli pizzalar sunmakla kalmıyor, aynı zamanda sıcak bir atmosfer ve samimi bir deneyim sunuyoruz. İtalyan mutfağının en özel tariflerini, özenle seçilmiş malzemelerle birleştiriyor ve sizlere sunuyoruz. Her dilde mutluluğunuzu ifade etmek için Grazie Pizza her zaman burada!",
  btnText: "daha fazlasını keşfedin",
  image: AboutImg,
};

export const menuData = {
  title: "Lezzetin Zamanı Geldi!",
  subtitle: "babaanne tarifi ile pizzalar",
  modelImg: ModelWhiteImg,
  btnText: "Sipariş verin",
  menuItems: [
    {
      image: Margherita,
      name: "Margherita Pizza",
      description:
        "Fırınlanmış taze domatesli pizza sosu, mozzarella peyniri, soğuk sıkım zeytinyağı, fesleğen",
    },
    {
      image: Mix,
      name: "Grazie Mix Pizza",
      description:
        "Fırınlanmış taze domatesli pizza sosu, mozzarella peyniri, dana salam, kasap sucuk, dana sosis, sotelenmiş mantar, dilim zeytin, süt mısır, kırmızıbiber, yeşil köy biberi",
    },
    {
      image: Kofteli,
      name: "Köfteli Pizza",
      description:
        "Fırınlanmış taze domatesli pizza sosu, mozzarella peyniri, mini köfte, karamelize soğan, közlenmiş patlıcan, soğuk sıkım zeytinyağı, dağ kekiği, fesleğen",
    },
    {
      image: Vejeteryan,
      name: "Grazie Vejeterjan Pizza",
      description:
        "Kepekli pizza hamuru, fırınlanmış taze domatesli pizza sosu, mozzarella peyniri, sotelenmiş mantar, dilim zeytin, süt mısır, ıspanak, kurutulmuş domates, soğan, soğuk sıkım zeytinyağı, dağ kekiği, fesleğen",
    },
    {
      image: Asado,
      name: "Grazie Asado Pizza",
      description:
        "Kemik iliği, demi glace sos, 8 saat fırınlanmış dana kaburga, mozzarella peyniri, sotelenmiş mantar",
    },
    {
      image: Bambino,
      name: "Grazie Bambino Pizza",
      description:
        "Fırınlanmış taze domatesli pizza sosu, kasap sucuk, dana sosis, dilim zeytin, süt mısır",
    },
    {
      image: Formaggi,
      name: "Quattro Formaggi Pizza",
      description:
        "Özel krema sosu, mozzarella peyniri, parmesan peyniri, gouda peyniri, rokfor peyniri, dağ kekiği",
    },
    {
      image: Funghi,
      name: "Funghi Pizza",
      description:
        "Özel krema sosu, mozzarella peyniri, kestane mantarı, istiridye mantarı, kültür mantarı, soğuk sıkım zeytinyağı, dağ kekiği, fesleğen",
    },
    {
      image: AllTonto,
      name: "Pizza All Tonno",
      description:
        "Fırınlanmış taze domatesli pizza sosu, mozzarella peyniri, ton balığı, kırmızı soğan, dilim zeytin, süt mısır, soğuk sıkım zeytinyağı, dağ kekiği, fesleğen",
    },
    {
      image: BBQ,
      name: "BBQ Tavuklu Pizza",
      description:
        "Fırınlanmış taze domatesli pizza sos, mozzarella peyniri, karamelize soğan, cheddar peyniri, şerit tavuk parçaları, kırmızı köy biberi, bbq sos",
    },
    {
      image: Fume,
      name: "Füme Etli Pizza",
      description:
        "Fırınlanmış taze domatesli pizza sos, mozzarella peyniri, füme et",
    },
  ],
};

export const teamData = {
  pretitle: "ekibimiz",
  title: "şefimizle tanışın",
  sub1: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis adipisci repudiandae enim ratione corrupti voluptatum suscipit distinctio dolor.",
  sub2: "Sequi exercitationem quae deserunt reiciendis nesciunt perferendis atque quisquam, odit facere! Minima esse reiciendis, magnam fugiat totam maxime consequatur perspiciatis voluptas consequuntur.",
  name: "elif naz elden",
  occupation: "şef",
  signatureImg: SignatureImg,
  chefImg: ChefImg,
};

export const testimonialData = {
  title: "bizim İçin ne dediler?",
  subtitle: "müşteri yorumları",
  modelImg: ModelWhiteImg,
  _slider: [
    {
      message:
        "Margherita pizzayı denedim ve bayıldım! Özel pizza sosu ve mozzarella peynirinin mükemmel uyumu, cherry domateslerin tatlılığı ile birleşince eşsiz bir lezzet ortaya çıkıyor. Pesto sosunun aroması, pizzaya taze bir dokunuş katıyor. Malzemelerin tazeliği ve lezzeti damağımda unutulmaz bir iz bıraktı. Kesinlikle tekrar gideceğim ve herkesin denemesini tavsiye ederim!",
      name: "Tanju Koç",
      occupation: "Margherita",
    },
    {
      message:
        "Özel sosun zenginliği, mozzarella ile birleşince harika bir temel oluşturmuş. Üzerine eklenen pastırma ve sucuk, pizza lezzetini katlayarak gerçek bir Türk mutfağı deneyimi sunuyor. Kırmızı biber ve mantarın eklenmesiyle lezzete tatlı bir çeşnilik katılmış. Her lokmada malzemelerin tazeliği ve zenginliği hissediliyor. Kesinlikle tekrar ziyaret edeceğim ve herkese öneriyorum!",
      name: "Melek Akbulut",
      occupation: "Mediterrian",
      occupation: "Turkish",
    },
    {
      message:
        "Yeni keşfettiğim pizzacıda Mediterrian pizzasına bayıldım! Özel sos ve mozzarella, cherry domateslerin tatlılığıyla ve aromatik pesto ile mükemmel uyum sağlıyor. Malzemelerin tazeliği ve pizza kenarlarının mükemmel pişirilmesi dikkat çekici. Pesto kullanımı çok başarılı. Kesinlikle tekrar gideceğim ve herkese tavsiye ediyorum!",
      name: "Yasemin Yılmaz",
      occupation: "Mediterrian",
    },
  ],
  get slider_1() {
    return this._slider;
  },
  set slider_1(value) {
    this._slider = value;
  },
  get slider() {
    return this._slider;
  },
  set slider(value) {
    this._slider = value;
  },
};

export const reservationData = {
  title: "rezervasyon",
  subtitle:
    "Call (800) 123-4567 from 5AM - 11PM daily, or book online with OpenTable.Reservations required for parties of 6 or more.",
  modelImg: ModelBlackImg,
  btnText: "reservasyon yap",
};

export const newsletterData = {
  title: "yeniliklerden haberdar olun!",
  subtitle: "Kampanyalardan ve daha fazlasından haberdar olmak için,",
  placeholder: "Enfes tariflerimize abone olun!",
  btnText: "abone olun",
};

export const footerData = {
  contact: {
    title: "İletişim",
    address: "Güngören mah. Pelit sk. No:6 Çekmeköy/Istanbul",
    phone: "(+90) 538 789 58 53",
  },
  hours: {
    title: "Çalışma saatleri",
    program: [
      {
        days: "pazartesi - pazar",
        hours: "11:00 - 23:00",
      },
    ],
  },
  social: {
    title: "sosyal medya",
    icons: [
      { href: "/", icon: <FaYoutube /> },
      { href: "/", icon: <FaFacebookF /> },
      { href: "/", icon: <FaInstagram /> },
      { href: "/", icon: <FaPinterestP /> },
      { href: "/", icon: <FaDiscord /> },
    ],
  },
};
