import React from "react";
import { menuData } from "../data";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../variants";

const Menu = () => {
  const { title, subtitle, modelImg, menuItems, btnText } = menuData;
  return (
    <section className="min-h-[780px]" id="menu">
      <div className="h-[720px] bg-menu absolute w-full max-w-[1800px] -z-0"></div>
      <div className="relative z-10 top-72 lg:top-52">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.1 }}
          className="container mx-auto flex flex-col items-center text-center"
        >
          <motion.h2
            variants={fadeIn("down", "tween", 0.2, 1.6)}
            className="h2 capitalize text-white max-w-[400px] text-center"
          >
            {title}
          </motion.h2>
          <motion.p
            variants={fadeIn("down", "tween", 0.4, 1.6)}
            className="text-white/70 capitalize mb-8"
          >
            {subtitle}
          </motion.p>
          <motion.div variants={fadeIn("down", "tween", 0.6, 1.6)}>
            <img src={modelImg} alt="" />
          </motion.div>
        </motion.div>
      </div>
      <motion.div
        initial={{
          opacity: 0,
          y: 150,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          type: "tween",
          delay: 0.2,
          duration: 1.6,
          ease: "easeOut",
        }}
        className="relative top-80 lg:top-96"
      >
        <div className="container mx-auto">
          <div className="lg:-mt-24 min-h-[590px] z-30 mb-8 md:mb-4 xl:mb-16">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 min-h-[590px]">
              {menuItems.map((item, index) => {
                const { image, name, description } = item;
                return (
                  <div
                    key={index}
                    className="bg-white rounded-lg overflow-hidden shadow-lg"
                  >
                    <img src={image} alt={name} className="w-full" />
                    <div className="p-4">
                      {" "}
                      <h3 className="text-xl font-bold text-center text-black">
                        {name}
                      </h3>{" "}
                      <p className="text-md text-gray-700 text-center">
                        {description}
                      </p>{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <a
            href="https://www.yemeksepeti.com/restaurant/dzo2/grazie-pizza"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn mx-auto capitalize">{btnText}</button>
          </a>
        </div>
      </motion.div>
    </section>
  );
};

export default Menu;
